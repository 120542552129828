import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { filtroPorTabelaStore } from '@/store';
let FiltrosPorTabela = class FiltrosPorTabela extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Exibir', value: 'show' },
            { text: 'Coluna', value: 'text' }
        ];
        this.search = '';
        this.dialog = false;
        this.loading = true;
        this.loadingSave = false;
        this.items = [];
        this.selectAllCheckbox = false;
    }
    selectAll(value) {
        this.items.forEach((item) => {
            item.show = value;
        });
    }
    checkSelected() {
        this.selectAllCheckbox = this.items.some((i) => i.show);
    }
    async salvar() {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        if (this.propTableName) {
            const items = this.items.filter((item) => item.show);
            await filtroPorTabelaStore.filtroPorTabelaCreate({
                items,
                table_name: this.propTableName
            });
        }
        this.$emit('on-confirm', this.items);
        this.loadingSave = false;
        this.dialog = false;
    }
    async show() {
        this.loading = true;
        this.dialog = true;
        this.items = this.propItems;
        this.selectAllCheckbox = this.items.some((i) => i.show);
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao(this.propTableName);
        this.loading = false;
    }
};
__decorate([
    Prop(Array)
], FiltrosPorTabela.prototype, "propItems", void 0);
__decorate([
    Prop({
        type: String, default: ''
    })
], FiltrosPorTabela.prototype, "propTableName", void 0);
FiltrosPorTabela = __decorate([
    Component
], FiltrosPorTabela);
export default FiltrosPorTabela;
