import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, parceriaStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import FiltroPorTabela from '@/components/FiltrosPorTabela.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome', show: true },
            { text: 'Status', value: 'status_id', show: true },
            { text: 'Nicho', value: 'nicho_id', show: true },
            { text: 'Descrição', value: 'descricao', show: true },
            { text: 'Data de início', value: 'data_inicio', show: true },
            { text: 'Data de término', value: 'data_termino', show: true },
            { text: 'Entrada de cupons', value: 'total_entrada', show: true },
            { text: 'Saída de cupons', value: 'total_saida', show: true },
            { text: 'Saldo de cupons', value: 'total_saldo', show: true },
            { text: 'CEP', value: 'cep' },
            { text: 'Endereço', value: 'rua' },
            { text: 'Número de endereço', value: 'numero' },
            { text: 'Complemento', value: 'complemento' },
            { text: 'Bairro', value: 'bairro' },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tabelaRef = null;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Parcerias) {
            return userToolStore.userToolsIndexed.Parcerias[0].allow_delete;
        }
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    get headersFiltered() {
        return _.filter(this.headers, (item) => item.show);
    }
    showFiltroPorTabela() {
        const form = this.$refs.filtroPorTabela;
        form.show('parceria');
    }
    async onUpdateHeaders(headers) {
        this.headers = headers;
    }
    // public async deleteItem(id: number) {
    //   this.$swal({
    //     title: 'Confirma a exclusão da parceria?',
    //     text: 'Após exclusão a parceria não pode ser recuperada!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonText: 'Cancelar',
    //     confirmButtonText: 'Sim, eu confirmo!',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       this.loading = true;
    //       // @ts-ignore
    //       await parceriaStore.deleteParceria(id);
    //       // @ts-ignore
    //       await parceriaStore.getParcerias();
    //       this.loading = false;
    //     }
    //   });
    // }
    async created() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async mounted() {
        this.loading = true;
        await parceriaStore.getParcerias();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-parcerias-edit', params: { id } });
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            FiltroPorTabela,
            ImprimirTabelaComponent,
            TextExpandableComponent,
        },
    })
], List);
export default List;
